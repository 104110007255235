"use client";

import { ChevronRightSvg } from "@/public/svg";
import Button from "../atom/Button";

export default function InputTitle({ title }) {
  return (
    <label className='p1-medium text-greyscaleGON-700 capitalize'>
      {title}
    </label>
  );
}

export function TitleAction({
  title,
  handleAction = {
    title: "",
    onclick: () => {},
    icon: undefined,
    show: true,
  },
}) {
  const Icon = handleAction?.icon;
  return (
    <div className='flex justify-between items-center'>
      <p className='h6-semiBold '>{title}</p>
      {handleAction?.show && (
        <Button
          label={handleAction?.title}
          variants='text'
          onClick={() => handleAction?.onclick && handleAction?.onclick()}
          className='pr-0'
          icon={{
            right: (props) =>
              !handleAction?.icon ? (
                <ChevronRightSvg {...props} />
              ) : (
                <Icon {...props} />
              ),
          }}
        />
      )}
    </div>
  );
}
