"use client";

import {
  useCheckBreakPoint,
  useCheckBreakPointCustom
} from "@/functions/usResponsive";
import { memo } from "react";
import { twMerge } from "tailwind-merge";

function Container({ children, colors = "", className, showMQ = true }) {
  colors = `${colors}GON`;

  let breakpoint = useCheckBreakPoint();
  let breakpointCustom = useCheckBreakPointCustom();
  breakpoint = breakpointCustom?.length > 0 ? "ui-laptop" : breakpoint;

  return (
    <div className={`flex bg-${colors}-50 justify-center`}>
      <div className={twMerge(`flex w-full max-w-gon`, className)}>
        {showMQ && (
          <div className="p-2  justify-end w-full flex bg-successGON-50">
            {breakpoint == "ui-laptop" && (
              <div className="p1-medium uppercase">laptop</div>
            )}
            {breakpoint == "ui-tablet" && (
              <div className="p1-medium uppercase">tablet</div>
            )}
            {breakpointCustom == "ui-tablet-mini" && (
              <div className="p1-medium uppercase">tablet mini</div>
            )}
            {breakpoint == "ui-phone" && (
              <div className="p1-medium uppercase">phone</div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default memo(Container);
