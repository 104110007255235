"use client";

import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import InputTitle from "../sub-atom/InputTitle";
import { ChevronDownSvg, ChevronUpSvg } from "@/public/svg";

export default function Input({
  disabled = false,
  disableInput = false,
  size = "medium",
  colors = "primary",
  type = "text",
  placeholder = "Text PlaceHolder",
  error = false,
  label,
  footer = {
    title: "",
    onClick: () => {},
  },
  icon = {
    left: undefined,
    onClickLeft: () => {},
    right: undefined,
    onClickRight: () => {},
  },
  variants = "input",
  handleDropdown = {
    show: false,
    datas: [],
    showHIdden: () => {},
    onChange: () => {},
    value: "",
  },
  caption = "",
  value,
  onChange = () => {},
  onKeyDown,
  enterKeyHint,
  onBlur,
  onSubmit,
  rangeDate = {
    min: undefined,
    max: undefined,
  },
}) {
  const showDropdown = handleDropdown?.show;
  let isDropdown = variants == "dropdown";
  icon = isDropdown
    ? {
        right: (props) =>
          showDropdown ? (
            <ChevronUpSvg {...props} />
          ) : (
            <ChevronDownSvg {...props} />
          ),
        onClickRight: () => !disabled && handleDropdown?.showHIdden(),
      }
    : icon;

  const [activeColor, setActiveColor] = useState("");
  const [filledColor, setFilledColor] = useState("");

  const {
    nameColors,
    nameColorsWithStatus,
    nameColorsWithStatusBorder,
    padding,
    IconButton,
    defaultColor,
  } = useMemo(() => {
    const nameColors = `${colors}GON`;
    const nameColorsWithStatus = !error ? "successGON" : "errorGON";
    const nameColorsWithStatusBorder = !error ? "greyscaleGON" : "errorGON";
    const listSize = {
      padding: {
        small: "p-2",
        medium: "p-3",
        large: "p-4",
      },
    };

    const listVariant = {
      input: `text-${nameColorsWithStatusBorder}-400 border border-${nameColorsWithStatusBorder}-200`,
      textarea: `text-${nameColorsWithStatusBorder}-400 border border-${nameColorsWithStatusBorder}-200`,
      dropdown: `text-${nameColorsWithStatusBorder}-600 border border-${nameColorsWithStatusBorder}-200 `,
    };

    return {
      padding: listSize?.padding?.[size],
      nameColors,
      nameColorsWithStatus,
      nameColorsWithStatusBorder,
      IconButton: {
        left: icon?.left,
        right: icon?.right,
      },
      defaultColor: listVariant?.[variants],
    };
  }, [colors, error, size, icon, variants]);

  return (
    <div
      className={twMerge("bg-shadesGON-50 w-full", isDropdown && "relative")}>
      <div className='flex w-full flex-col gap-4'>
        {!label ? null : <InputTitle title={label} />}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit && onSubmit(e);
          }}
          className={twMerge(
            defaultColor,
            padding,
            `group flex gap-2 items-center rounded justify-center`,
            activeColor,
            filledColor,
            disabled
              ? "bg-greyscaleGON-100"
              : `hover:border-${nameColors}-400 bg-shadesGON-50`
          )}>
          {/* icon left */}
          {icon?.left && (
            <button
              onClick={(e) => {
                e.preventDefault();
                !disabled && icon?.onClickLeft && icon?.onClickLeft();
              }}>
              <IconButton.left className={`h-icon-small w-icon-small`} />
            </button>
          )}

          {/* inputs */}
          {variants == "textarea" ? (
            <textarea
              placeholder={placeholder}
              disabled={disabled || disableInput}
              className={twMerge(
                disabled ? "bg-greyscaleGON-100" : "bg-shadesGON-50",
                `min-h-[88px] p2-regular outline-none flex w-full items-center justify-center text-shadesGON-100 caret-${nameColors}-400`,
                isDropdown && `placeholder-gray-600`
              )}
              onChange={(props) => {
                onChange && onChange(props?.target?.value);

                // handleColor
                setFilledColor("");
                value?.toString()?.length >= 1 &&
                  setActiveColor(
                    `text-${nameColors}-400 border-${nameColors}-400`
                  );

                // handle dropdown
                isDropdown &&
                  !handleDropdown?.show &&
                  handleDropdown?.showHIdden();
              }}
              onBlur={() => {
                // handleColor
                onBlur && onBlur();
                setActiveColor("");
              }}
              value={value}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.key === "Enter") {
                  onKeyDown && onKeyDown();
                }
              }}
            />
          ) : (
            <input
              min={rangeDate?.min}
              max={rangeDate?.max}
              placeholder={placeholder}
              disabled={disabled || disableInput}
              type={type}
              className={twMerge(
                disabled ? "bg-greyscaleGON-100" : "bg-shadesGON-50",
                `p2-regular outline-none flex w-full items-center justify-center text-shadesGON-100 caret-${nameColors}-400`,
                isDropdown && `placeholder-gray-600`
              )}
              onFocus={() => {
                !disabled && !showDropdown && handleDropdown?.showHIdden();
                isDropdown && onChange && onChange("");
              }}
              onChange={(props) => {
                onChange && onChange(props?.target?.value);

                // handleColor
                setFilledColor("");
                value?.toString()?.length >= 1 &&
                  setActiveColor(
                    `text-${nameColors}-400 border-${nameColors}-400`
                  );

                // handle dropdown
                isDropdown &&
                  !handleDropdown?.show &&
                  handleDropdown?.showHIdden();
              }}
              onBlur={() => {
                // handleColor
                onBlur && onBlur();
                setActiveColor("");
              }}
              value={value}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.key === "Enter") {
                  onKeyDown && onKeyDown();
                }
              }}
              enterKeyHint={enterKeyHint ?? ""}
            />
          )}

          {/* icon right */}
          {icon?.right && (
            <div
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                !disabled && icon?.onClickRight && icon?.onClickRight();
              }}>
              <IconButton.right className={`h-icon-small w-icon-small`} />
            </div>
          )}
        </form>

        {/* hidden if dropdwon */}
        {(caption?.length > 0 || footer?.title) && !isDropdown && (
          <div className='flex gap-2 items-center truncate'>
            {!caption ? null : (
              <Caption
                error={error}
                caption={caption}
              />
            )}
            {!footer ? null : <InputFooter footer={footer} />}
          </div>
        )}
      </div>

      {/* dropdown */}
      {showDropdown && !disabled && (
        <div
          className={twMerge(
            `absolute mt-2 bg-shadesGON-50  shadow-largeGON w-full h-auto max-h-[200px] overflow-y-scroll`
          )}>
          {handleDropdown?.datas?.map((item, key) => (
            <div
              onClick={() => {
                handleDropdown?.showHIdden();
                handleDropdown?.onChange && handleDropdown?.onChange(item);
              }}
              key={key}
              className={twMerge(padding, "cursor-pointer")}>
              <p
                className={twMerge(
                  handleDropdown?.value == item?.title
                    ? "text-primaryGON-400"
                    : "text-greyscaleGON-700",
                  "p1-regular max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                )}>
                {item?.title ?? "Not Found"}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function InputFooter({ footer }) {
  return (
    <div className='flex w-full p1-medium text-primaryGON-400 justify-end select-none'>
      <span
        className='text-right cursor-pointer'
        onClick={() => footer?.onClick && footer?.onClick()}>
        {footer?.title}
      </span>
    </div>
  );
}

export function Caption({ caption, error }) {
  const textColor = error ? "text-errorGON-400" : "text-greyscaleGON-400";
  return (
    <span
      className={twMerge(
        textColor,
        `flex w-full p2-regular justify-start text-left`
      )}>
      {caption}
    </span>
  );
}
