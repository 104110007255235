"use client";

import { footerBenefitList, listSosmed } from "@/functions/dinamisData";
import Container from "./Container";
import Divider from "./Divider";
import Input from "./Input";
import Button from "./Button";
import { Copyright, SendSvg } from "@/public/svg";
import Image from "next/image";
import Link from "next/link";
import { BlurImage } from "@/public/images";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { addSubscriber } from "@/functions/databaseData";
import useDelayedRender from "@/functions/useDelayedRender";

const settingToast = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default function Footer() {
  const listBenefit = footerBenefitList({});
  const listOther = footerBenefitList({ other: true });
  const listDataSosmed = listSosmed({});
  const [email, setEmail] = useState("");

  async function handleSubscriber() {
    try {
      if (!isValidEmail(email)) throw new Error("Invalid email");
      let res = await addSubscriber({
        email,
      });
      if (res?.status == "success") {
        return toast.success(
          res?.message ?? `Berhasil subscribe ${process.env.COMPANY_NAME}`,
          settingToast
        );
      }
      throw new Error(res?.error?.message ?? res?.error);
    } catch (error) {
      toast.error(`error : ${error.message}`, settingToast);
    }
  }

  return (
    <footer className={"bg-greyscaleGON-700"}>
      <Container showMQ={false} className="flex flex-col gap-10">
        <div className="container-mini py-10 flex flex-col gap-8">
          <div className="grid-footer-list">
            {listBenefit?.map((item, index) => {
              const Icon = item?.icon;
              return (
                <div key={index} className="flex flex-col gap-4 w-full">
                  {!item?.icon ? null : (
                    <Icon className="text-shadesGON-50 h-12 w-12" />
                  )}
                  <div className="flex flex-col gap-2">
                    <p className="p1-semiBold text-primaryGON-100">
                      {item?.title}
                    </p>
                    <p className="p2-regular text-greyscaleGON-300 ">
                      {item?.desc}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Divider />
          <div className="grid-footer-list">
            {listOther?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-4 w-full">
                  <p className="p1-semiBold text-primaryGON-100">
                    {item?.title}
                  </p>
                  {!item?.list ? null : (
                    <div className="flex flex-col gap-2">
                      {item?.list?.map((item, index) => (
                        <Link
                          aria-label={`${item?.title}`}
                          as={item?.url ?? "/"}
                          shallow
                          href={item?.url ?? "/"}
                          key={index}
                          className="a2-regular text-greyscaleGON-300 cursor-pointer"
                        >
                          {item?.title}
                        </Link>
                      ))}
                    </div>
                  )}
                  {!item?.desc ? null : (
                    <p className="p2-regular text-greyscaleGON-300 ">
                      {item?.desc}
                    </p>
                  )}
                  {!item?.email ? null : (
                    <div className="flex flex-col gap-2">
                      <Input
                        placeholder="Masukkan email anda"
                        variants={"solid"}
                        onChange={(e) => setEmail(e)}
                        onSubmit={() => handleSubscriber()}
                      />
                      <Button
                        label="Kirim"
                        className="w-fit"
                        onClick={() => handleSubscriber()}
                        icon={{
                          left: (props) => <SendSvg {...props} />,
                        }}
                      />
                    </div>
                  )}
                  {!item?.images ? null : (
                    <div className="grid grid-cols-3">
                      {item?.images?.map((item, index) => (
                        <Image
                          unoptimized
                          className="w-auto h-auto"
                          width={240}
                          height={240}
                          quality={85}
                          key={index}
                          alt={`images_${index}`}
                          src={item}
                          placeholder="blur"
                          blurDataURL={BlurImage?.blurDataURL}
                        />
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="container-mini pb-10 gap-6 justify-between flex flex-col-reverse md:flex-row ">
          <div className="flex gap-1 items-center text-greyscaleGON-300">
            <p>Copyright {new Date().getFullYear()}</p>
            <Copyright className="h-6 w-6 " />
            <p>IT {process.env.COMPANY_NAME}</p>
          </div>
          <ul className="flex gap-4">
            {listDataSosmed?.map((item, index) => {
              const Icon = item?.icon;
              return (
                <li key={index}>
                  <Link
                    aria-label={`${item?.value}`}
                    as={item?.url ?? "/"}
                    shallow
                    href={item?.url ?? "/"}
                  >
                    <Icon className="h-8 w-8 text-greyscaleGON-300" />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </footer>
  );
}
