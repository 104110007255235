import useBreakpoint from "use-breakpoint";

export function useCheckBreakPoint() {
  const { breakpoint } = useBreakpoint({
    other: 0,
    xs: 425,
    sm: 640,
    lg: 1024,
  });

  const { breakpoint: breakpointOther } = useBreakpoint({
    xs: 425,
    sm: 640,
  });

  let teddi = "";
  teddi = breakpoint == "lg" ? "ui-laptop" : teddi;
  teddi = breakpoint == "sm" ? "ui-tablet" : teddi;
  teddi = breakpoint == "xs" || breakpoint == "other" ? "ui-phone" : teddi;

  return teddi;
}

export function useCheckBreakPointCustom() {
  const { breakpoint: breakpoint } = useBreakpoint({
    xs: 425,
    sm: 640,
  });

  let teddi = "";
  teddi = breakpoint == "xs" ? "ui-tablet-mini" : teddi;

  return teddi;
}
