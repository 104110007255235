export { default as AvatarImage } from "./example-avatar.png";
export { default as BlurImage } from "./blur.jpg";
export { default as ComingSoon } from "./coming-soon.png";
export { default as LoginImage } from "./login-image.png";
export { default as EmptyImage } from "./empty-image.webp";

//kurir

export { default as SiCepat } from "./siCepat.png";
export { default as JNE } from "./jne.png";
export { default as JNT } from "./jnt.png";
export { default as AnterAja } from "./anteraja.png";
export { default as Ninja } from "./ninja.png";
export { default as PosIndonesia } from "./posIndonesia.png";
export { default as Wahana } from "./wahana.png";
export { default as LionParcel } from "./lionParcel.png";
